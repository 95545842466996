import React from "react";
import { Navigate } from "react-router-dom";
import AuthUser from "./hooks/AuthUser";

const ProtectedRoute = ({ children }) => {
  const { getToken } = AuthUser();
  if (!getToken()) {
    return <Navigate to="login" />;
  }
  return children;
};

export default ProtectedRoute;